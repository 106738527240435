<template>
    <div>
        <b-table small :fields="fields" :items="items" stacked="lg"  striped hover outlined>
            <slot></slot>
            <!-- A virtual column -->
            <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template v-slot:cell(url)="data">
                <span v-html="data.value"></span>
            </template>
            <template v-slot:cell(labels)="data">
                <span v-for="label,label_index in data.value" class="badge" :style="{'background-color':label.color=='sky'?'#0098b7':label.color,'color':label.color=='yellow' ? '#000':'#FFF'}">
                    {{label.name}}
                </span>
            </template>

            <template v-slot:cell(show_details)="card">
                <button class="btn btn-success btn-sm" @click="card.toggleDetails" class="mr-2" v-if="card.item.checklists.length > 0">
                    {{ card.detailsShowing ? '隱藏' : '顯示'}} Check List ({{card.item.checklists.length}})
                </button>
            </template>

            <template v-slot:row-details="card">
                <div class="row">
                    <div class="col-md-3" v-if="card.item.checklists.length > 0" v-for="checklist,checklistIndex in card.item.checklists">
                        <div  class="card">
                            <div class="card-body">
                                <h6>{{checklist.name}}</h6>
                                <ul class="list-group list-group-flush">
                                    <li v-for="checkItem,checkItemIndex in checklist.checkItems" class="list-group-item">
                                        <del v-if="checkItem.state == 'complete'" style="color:green;"> {{checkItem.name}} </del>
                                        <div v-else style="color:gray;">{{checkItem.name}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </template>

        </b-table>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { TablePlugin } from 'bootstrap-vue';
    Vue.use(TablePlugin);
    export default {
        name: "my-table",
        data() {
            return {
                fields: [
                    // A virtual column that doesn't exist in items
                    'index',

                ],
                items: []
            }
        },
        mounted() {
            this.items = this.data;
            this.fields = this.columns;
          // console.log(this.items);
        },
        watch:{
            data(){
                this.items = this.data;
                // console.log(this.items);

            },
            columns(){
                this.fields = this.columns;
            }
        },
        props:{
            data:{
                type:Array,
                default(){
                    return []
                }
            },
            columns:{
                type:Array,
                default(){
                    return []
                }
            }

        }
    }
</script>

<style scoped>

</style>