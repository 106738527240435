import Vue from 'vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);

import { TablePlugin } from 'bootstrap-vue'
Vue.use(TablePlugin);

import MyTable from "./vue/my-table";
// Vue.use( MyTable);

Vue.component('my-table', MyTable)

// window.MyTable = MyTable;
window.Vue = Vue;